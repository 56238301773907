.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}

.submit-form {
  max-width: 300px;
  margin: auto;
}

.edit-form {
  max-width: 300px;
  margin: auto;
}

.ant-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #272b41;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}

.ant-avatar-lg {
  width: 34px;
  height: 34px;
  line-height: 34px;
  border-radius: 50%;
}

.kwjZhs .ant-avatar {
  margin: 10px;
  background: rgb(227, 230, 239);
}

.avtar-round-header {
  background-color: #B54191;
  vertical-align: middle;
}

.avtar-round-hstring {
  transform: scale(1) translateX(-50%);
  font-weight: bold;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.sidebar_nav a.active,
#pageSubmenu li a.active {
  color: #ffffff !important;
  background: #a3afb6 !important;
}

.rdt_TableHead .rdt_TableHeadRow {
  background: #f8f9fb;
  font-size: 14px;
  min-height: 42px;
}

.rdt_TableBody .rdt_TableRow {
  min-height: auto !important;
  padding: 11px 10px 8px;
  line-height: 2.5 !important;
  color: #6c757d !important;
  border-bottom: 1px solid #f1f2f6 !important;
  font-size: 14px;
}

.add-reservetion{
  background-color: #53647b;
}

.add-reservetion .drawer-title{
  color: #fff;
}

.sidebar-background-color{
  background-color: #f1f1f1;
}

.hr-1{
  margin-top: 1.67rem;
  margin-bottom: 1.67rem;
}

/* .custom-checkbox input[type="checkbox"]+label:after{
  top: 11px !important;
}

.custom-checkbox input[type="checkbox"]+label {
  line-height: 2.4 !important;
} */

/*.kJrhuj option:hover
{
  background: red!important;
}

.kJrhuj option:checked,
.kJrhuj option:hover {
    box-shadow: 0 0 10px 100px #000 inset;
}*/

.bvxQGL {
  fill: #B54191 !important;
}

.bvxQGL:hover {
  background: #B54191 !important;
  fill: #fff !important;
}

.navbar-right__menu>li {
  padding: 20px 12px;
}

/*  Table Action */
.table_actions li a.view:hover i {
  color: #5f63f2;
}

.table_actions li a.edit:hover i {
  color: #2c99ff;
}

.table_actions li a.remove:hover i {
  color: #ff4d4f;
}

.custom-btn option:hover {
  background-color: blueviolet !important;
  border: #fff !important;
}

.bootstrap-select.btn-group .dropdown-menu li a:hover {
  color: whitesmoke !important;
  background: #bf5279 !important;
}


.footer-wrapper{
  position: fixed;
}